import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

const Skills = () => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            angular: file(relativePath: { eq: "skills/angular.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            aws: file(relativePath: { eq: "skills/aws.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            css: file(relativePath: { eq: "skills/css3.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            gatsby: file(relativePath: { eq: "skills/gatsby.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            html: file(relativePath: { eq: "skills/html5.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            ionic: file(relativePath: { eq: "skills/ionic.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            java: file(relativePath: { eq: "skills/java.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            js: file(relativePath: { eq: "skills/js.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            python: file(relativePath: { eq: "skills/python.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            react: file(relativePath: { eq: "skills/react.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            sass: file(relativePath: { eq: "skills/sass.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            spring: file(relativePath: { eq: "skills/spring.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            typescript: file(relativePath: { eq: "skills/typescript.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            gitlab: file(relativePath: { eq: "skills/gitlab.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            heroku: file(relativePath: { eq: "skills/heroku.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            mysql: file(relativePath: { eq: "skills/mysql.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            php: file(relativePath: { eq: "skills/php.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            mongodb: file(relativePath: { eq: "skills/mongodb.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            elasticsearch: file(relativePath: { eq: "skills/elasticsearch.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Skills" />
            <h1>{intl.formatMessage({ id: "SKILLS.TITLE" })}</h1>        
            <div className="container">
                <div className="column">
                    <Img fluid={data.html.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column ">
                    <Img fluid={data.css.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.js.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.typescript.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column" >
                    <Img fluid={data.java.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.python.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.php.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.sass.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.angular.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.react.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.ionic.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.gatsby.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.spring.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.aws.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.heroku.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.gitlab.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.mongodb.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.mysql.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
                <div className="column">
                    <Img fluid={data.elasticsearch.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                </div>
            </div>

        </Layout>
    );
};

export default Skills;